<template>

  <b-card>
    <b-card-body class="card-padding">
      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
        <div>
          <div class="d-flex align-items-center mb-2">

            <h3 class="text-primary vuexy">
              Vuexy
            </h3>
          </div>
          <p class="card-text">
            Office 149, 450 South Brand Brooklyn
          </p>
          <p class="card-text">
            San Diego County, CA 91905, USA
          </p>
          <p class="card-text mb-0">
            +1 (123) 456 7891, +44 (876) 543 2198
          </p>
        </div>
        <div class="mt-md-0">
          <h4 class="invoice-title">
            Invoice
            <span class="invoice-number">#3492</span>
          </h4>
          <div class="d-flex align-items-center mt-3">
            <p class="invoice-date-title">
              Date Issued:
            </p>
            <p class="invoice-date">
              25/08/2020
            </p>
          </div>
          <div class="d-flex align-items-center">
            <p class="invoice-date-title">
              Due Date:
            </p>
            <p class="invoice-date">
              29/08/2020
            </p>
          </div>
        </div>
      </div>
    </b-card-body>

    <hr class="my-2">
    <b-card-body class="card-padding">
      <div class="row invoice-spacing">
        <div class="col-xl-8 p-0">
          <h6 class="mb-2">
            Invoice To:
          </h6>
          <h6 class="mb-25">
            Thomas shelby
          </h6>
          <p class="card-text">
            Shelby Company Limited
          </p>
          <p class="card-text">
            Small Heath, B10 0HF, UK
          </p>
          <p class="card-text">
            718-986-6062
          </p>
          <p class="card-text mb-0">
            peakyFBlinders@gmail.com
          </p>
        </div>
        <div class="col-xl-4 p-0 mt-xl-0 mt-2">
          <h6 class="mb-2">
            Payment Details:
          </h6>
          <div class="d-flex mt-1">
            <span>Total Due:</span>
            <span class="ml-2">$12,110.55</span>

          </div>
          <div class="d-flex mt-1">
            <span>Total Due:</span>
            <span class="ml-2">$12,110.55</span>

          </div>
          <div class="d-flex mt-1">
            <span>Total Due:</span>
            <span class="ml-2">$12,110.55</span>

          </div>
          <div class="d-flex mt-1">
            <span>Total Due:</span>
            <span class="ml-2">$12,110.55</span>

          </div>
          <div class="d-flex mt-1">
            <span>Total Due:</span>
            <span class="ml-2">$12,110.55</span>

          </div>
        </div>
      </div>
    </b-card-body>
    <b-row class="row-bg">
      <b-col>
        <div>
          TASK DESCRIPTION</div>
      </b-col>
      <b-col>
        <b-row
          class="d-flex justify-content-around"
        >
          <div>RATE</div>
          <div>HOURS</div>
          <div>TOTAL</div>
        </b-row>
      </b-col>
    </b-row>
    <b-card-body>
      <b-row class="card-border">
        <b-col>
          <div>Native App Development</div>
          <span>Developed a full stack native app using React Native, Bootstrap & Python</span>
        </b-col>
        <b-col>
          <b-row
            class="d-flex justify-content-around"
          >
            <div>$60.00</div>
            <div>30</div>
            <div>$1,800.00
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr class="my-2">
      <b-row class="card-border">
        <b-col>
          <div>Native App Development</div>
          <span>Developed a full stack native app using React Native, Bootstrap & Python</span>
        </b-col>
        <b-col>
          <b-row
            class="d-flex justify-content-around"
          >
            <div>$60.00</div>
            <div>30</div>
            <div>$1,800.00
            </div>
          </b-row>
        </b-col>
      </b-row>
      <hr class="my-2">
      <b-row class="card-border">
        <b-col
          md="6"
          class="
            order-md-1
            order-2
            mt-md-0
            mt-3"
        >
          <div class="d-flex">
            <div>Salesperson:</div>
            <span class="ml-2">Alfie Solomons</span>
          </div>
        </b-col>
        <b-col
          md="6"
          class="d-flex
            justify-content-end
            order-md-2
            order-1"
        >
          <div class="invoice-total-wrapper">
            <div class="invoice-total-item d-flex justify-content-between">
              <p class="invoice-total-title">
                Subtotal:
              </p>
              <p class="invoice-total-amount">
                $1800
              </p>
            </div>
            <div class="invoice-total-item d-flex justify-content-between">
              <p class="invoice-total-title">
                Discount:
              </p>
              <p class="invoice-total-amount">
                $28
              </p>
            </div>
            <div class="invoice-total-item d-flex justify-content-between">
              <p class="invoice-total-title">
                Tax:
              </p>
              <p class="invoice-total-amount">
                21%
              </p>
            </div>
            <hr class="my-50">
            <div class="invoice-total-item d-flex justify-content-between">
              <p class="invoice-total-title">
                Total:
              </p>
              <p class="invoice-total-amount">
                $1690
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr class="my-2">
      <div class="d-flex">
        <div>Note:</div>
        <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!</span>
      </div>
    </b-card-body>
  </b-card>

</template>
<script>
import {
  BCard, BCardBody, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BRow, BCol,
  },
}
</script>
<style scoped>
  .vuexy{
    font-size: 2.142rem;
    font-weight: 700;

  }
  .invoice-date-title {
    width: 7rem;
}
.card-padding{
  padding-left: 2.5rem;
    padding-right: 2.5rem;

}
.row-bg{
  background-color: #f3f2f7;
  padding: 10px;
}
.invoice-total-wrapper {
    max-width: 12rem;
    width: 100%;
}

</style>
